import { DynamicNumber, importDynamicNumber, TweenFunction } from '../../../../lib/defs/types';
import { tween } from '../../../../lib/util/tweens';
import { lerp } from '../../../math/math';
import { Particle } from '../Particle';
import { IBehavior } from './IBehavior';

// types
//-----------------------------------------------------------------------------
export type FadeBehaviorOptions = {
    type: 'fade';
    from?: DynamicNumber;
    to: DynamicNumber;
    tween?: TweenFunction;
};

/*
    fade/alpha behavior
*/
export class FadeBehavior implements IBehavior {
    // fields
    //-------------------------------------------------------------------------
    private _particle: Particle;
    private _options: FadeBehaviorOptions;
    private _from: number;
    private _to: number;
    private _tween: TweenFunction;

    // init
    //-------------------------------------------------------------------------
    constructor(particle: Particle, options: FadeBehaviorOptions) {
        this._particle = particle;
        this._options = options;
    }

    // impl
    //-------------------------------------------------------------------------
    public start() {
        const options = this._options;
        this._from = importDynamicNumber(options.from !== undefined ? options.from : this._particle.alpha);
        this._to = importDynamicNumber(options.to);
        this._tween = options.tween || tween.linear;
    }

    public step(dt: number) {
        // get tween value
        const tween = this._tween(this._particle.getTweenTime());

        // lerp to alpha
        this._particle.alpha = lerp(this._from, this._to, tween);
    }
}
