import { LanguageId } from '../defs/settings';

// constants
//-----------------------------------------------------------------------------
export default {
    name: {
        max: 40,
    },
    person: {
        defaultImage: 'player.default.png',
    },
    support: {
        email: 'pets-concept@play.co',
    },
    settings: {
        language: 'ja' as LanguageId,
    },
};
