import { DynamicNumber, importDynamicNumber, TweenFunction } from '../../../../lib/defs/types';
import { tween } from '../../../../lib/util/tweens';
import { Color } from '../../Color';
import { HslFilter } from '../../filters/HSL/HslFilter';
import { HSL } from '../../HSL';
import { Particle } from '../Particle';
import { IBehavior } from './IBehavior';

// types
//-----------------------------------------------------------------------------
export type TintBehaviorOptions = {
    type: 'tint';
    from?: DynamicNumber;
    to: DynamicNumber;
    luminance?: number;
    tween?: TweenFunction;
};

/*
    tween tint
*/
export class TintBehavior implements IBehavior {
    // fields
    //-------------------------------------------------------------------------
    private _particle: Particle;
    private _from: HSL;
    private _to: HSL;
    private _luminance: number;
    private _tween: TweenFunction;
    private _filter: HslFilter;

    // init
    //-------------------------------------------------------------------------
    constructor(particle: Particle, options: TintBehaviorOptions) {
        this._particle = particle;
        this._from = Color.from(importDynamicNumber(options.from || 0xffffff)).toHsl();
        this._to = Color.from(importDynamicNumber(options.to)).toHsl();
        this._luminance = options.luminance || 1;
        this._tween = options.tween || tween.linear;
        this._filter = new HslFilter();
        particle.filters = [this._filter];
    }

    // impl
    //-------------------------------------------------------------------------
    public step(dt: number) {
        // get tween value
        const tween = this._tween(this._particle.getTweenTime());

        // update color
        const hsl = this._from.lerp(this._to, tween);
        hsl.luminance *= this._luminance;
        this._filter.hsl = hsl;
    }
}
