import { IMediaInstance, Sound } from '@pixi/sound';
import { Plugin, PluginOptions } from '@play-co/astro';
import { Assets } from 'pixi.js';

import { PlayOptions, SoundChannel } from '../../lib/sound/SoundChannel';
import { firstTapPromise } from '../../lib/util/events';
import { arrayCreate } from '../../replicant/util/jsTools';
import type { App } from '../App';

// types
//-----------------------------------------------------------------------------
// public
export interface SoundServiceOptions extends PluginOptions {
    channels: number;
}

// private
type ChannelSession = {
    channelId: string;
    channel: SoundChannel;
    name?: string;
    instance?: IMediaInstance;
};

/*
    sound service. supports auto channel allocation.
*/
export class SoundService extends Plugin {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _options: Partial<SoundServiceOptions>;
    // state
    private _channels: ChannelSession[];

    // properties
    //-------------------------------------------------------------------------
    public set mute(mute: boolean) {
        // mute/unmute all channels
        for (const session of this._channels) {
            mute ? session.channel.muteAll() : session.channel.unmuteAll();
        }
    }

    // init
    //-------------------------------------------------------------------------
    constructor(app: App, options: Partial<SoundServiceOptions>) {
        super(app, options);
        this._options = options;
    }

    public async init() {
        //TODO: currently broken in pixi. loads all music despite preload being off.
        // load sound manifest. this should load instantly since assets should be set not to preload.
        //await Assets.loadBundle(['assets/sound']);

        //console.log(Assets.cache['_cache']);

        // create channels
        this._channels = arrayCreate(this._options.channels, (index) => {
            const channelId = `sfx${index}`;
            const channel = new SoundChannel(channelId);
            return { channelId, channel };
        });
    }

    public start(): void {}

    // api
    //-------------------------------------------------------------------------
    public async play(name: string, { volume = 1, dupes = 1, rate = 1 }: PlayOptions = {}) {
        //TODO: workaround to: await Assets.loadBundle(['assets/sound']);
        // load asset on demand
        await Assets.load(name);

        // require first tap cuz browser security reasons :\
        await firstTapPromise;

        // allocate channel session
        const channel = this._allocChannel(name, dupes);
        if (channel) {
            if (!channel.channel.exists(name)) {
                const sound = Assets.get<Sound>(name);
                channel.channel.add(name, sound);
            }
            // create instance
            channel.channel.speed(name, rate);
            channel.channel.volume(name, volume);
            const instance = (channel.instance = await channel.channel.play(name, {
                volume,
                rate,
            }));

            // on complete self remove
            instance.once('end', () => {
                channel.name = undefined;
                channel.instance = undefined;
            });
        }
    }

    // private: support
    //-------------------------------------------------------------------------
    public _allocChannel(name: string, dupeLimit: number): ChannelSession | undefined {
        let dupes = 0;

        // for each channel session
        for (const channel of this._channels) {
            // return if available
            if (!channel.name) {
                channel.name = name;
                return channel;
            }

            // dupe check
            if (channel.name === name && ++dupes >= dupeLimit) {
                break;
            }
        }

        return undefined;
    }
}
