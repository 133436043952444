import { ColorMatrixFilter, Container, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import app from '../../../index.entry';
import { Animation } from '../../../lib/animator/Animation';
import { BasicAsyncHandler, SizeType } from '../../../lib/defs/types';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import { TouchInputComponent } from '../../../lib/pixi/components/TouchInputComponent';
import { uiAlignBottom, uiAlignCenter, uiAlignCenterX, uiCreateQuad, uiSizeToFit } from '../../../lib/pixi/uiTools';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { tween } from '../../../lib/util/tweens';
import { arrayShuffle, sleep } from '../../../replicant/util/jsTools';
import { CircleProgress } from '../../concept/CircleProgress';
import { Fairy, FairyClipId } from '../../concept/Fairy';
import { pixiConfig } from '../../defs/config';
import { PetHelpFlow } from '../../flows/PetHelpFlow';
import { trackTapPet } from '../../lib/analytics/pets';
import { LayoutScreen2 } from '../../lib/screens/LayoutScreen2';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';
import { Pointer } from '../../lib/ui/Pointer';
import { BasicText } from '../../lib/ui/text/BasicText';

type HelpType = 'health' | 'nourish' | 'train';
type InitState = 'speech1' | 'custom';

const SPEECH_SPEED = 0.06;

const POINTER_X = 200;
const POINTER_Y = 130;
const POINTER_SCALE = 0.45;

const DEFAULT_DIALOGS = [
    '[petDialog0]',
    '[petDialog1]',
    '[petDialog2]',
    '[petDialog3]',
    '[petDialog4]',
    '[petDialog5]',
    '[petDialog6]',
    '[petDialog7]',
    '[petDialog8]',
];

// types
//-----------------------------------------------------------------------------
export type PetScreenOptions = {
    initState?: InitState; // optional to skip for tutorial, which will spawn in a scripted way
    showPointer?: boolean;
    petTreatHandler?: BasicAsyncHandler;
    petForageHandler?: BasicAsyncHandler;
};

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    health: 'button.treat.png',
    nourish: 'button.forage.png',
    train: 'button.strength.png',
    buttonFrame: 'frame.buttons.png',
    nameFrame: 'frame.name.fade.png',

    bigBubble: 'bubble.large.png',

    smallBubble: 'bubble.small.png',
    bubbleTail: 'bubble.tail.png',
    frame: 'dialogue.blue.fade.png',
};

const bgManifest = {
    default: 'bg.pets.default.png',
    zoomedOut: 'bg.pets.zoomed.out.png',
};

const DEFAULT_ACTIONS = ['health', 'nourish', 'train'] as HelpType[];

/*
   WIP HOME FOR PETS
*/
export class PetScreen extends LayoutScreen2 {
    // events
    //-------------------------------------------------------------------------
    // scene
    private _headerView: Container;
    private _bg: NineSlicePlane;
    private _menuContainer: Container;
    private _menuFrame: Sprite;
    private _pet: Container;
    private _fairy: Fairy;
    private _isTalking = false;
    private _flyAnimation: Animation;
    private _healthProgress: CircleProgress;
    private _foodProgress: CircleProgress;
    private _trainProgress: CircleProgress;
    private _pointer: Pointer;
    private _pointerAnimation: Animation;
    private _petName: BasicText;
    private _petDialogs: string[];
    private _checkLater: BasicText;

    private _skipSpeech = false;

    private _underlayInput: TouchInputComponent;

    public set isTalking(value: boolean) {
        this._isTalking = value;
    }

    public get headerView() {
        return this._headerView;
    }

    public get healthProgress() {
        return this._healthProgress;
    }

    public get foodProgress() {
        return this._foodProgress;
    }

    public get trainProgress() {
        return this._trainProgress;
    }

    // impl
    //-------------------------------------------------------------------------
    public preload(options: PetScreenOptions) {
        let bgAsset = bgManifest.default;
        if (options?.initState === 'speech1') bgAsset = bgManifest.zoomedOut;

        return app.resource.loadAssets([
            ...Object.values(manifest),
            bgAsset,
            ...Fairy.assets(),
            ...CircleProgress.assetsAll(),
            ...Pointer.assets(),
        ]);
    }

    public async init() {}

    public async spawning(options: PetScreenOptions) {
        // play music
        app.music.play('snow_forest.ogg');

        // reset dialogs
        this._petDialogs = arrayShuffle([...DEFAULT_DIALOGS]);

        // spawn scene
        this._spawn(options);

        if (!options?.initState) {
            this.spawnMenu(DEFAULT_ACTIONS, true);
            this._startPet();

            // show pointer if passed in (for example right after tutorial)
            if (options.showPointer) {
                this._pointer = new Pointer({ type: 'hand' });
                this._pointer.zIndex = 5;
                this._pet.addChild(this._pointer);
                const x = POINTER_X;
                const y = POINTER_Y;
                uiAlignCenter(this._fairy, this._pointer, 140, 150);
                this._pointer.position.set(x, y);
                this._pointer.scale.set(0);
                setTimeout(async () => {
                    // attach pointer to pet
                    this._pointerAnimation = this._pointer
                        .animate()
                        .add(
                            this._pointer.scale,
                            { x: this._pet.scale.x * POINTER_SCALE, y: POINTER_SCALE },
                            0.35,
                            tween.backOut(1.2),
                        );
                    // await this._pointerAnimation;
                    // this._pointerAnimation = this._pointer
                    //     .animate()
                    //     .add(this._pointer.position, { x, y: y + 6 }, 0.8, tween.powNInOut(1.2))
                    //     .add(this._pointer.position, { x, y: y - 6 }, 0.8, tween.powNInOut(1.2))
                    //     .loop();
                }, 1000);
            }
        }

        // preload
        sleep(0.5).then(() => app.nav.preload('petHelpPopup'));
    }

    public despawned() {
        this.empty();
    }

    public override resized(size: SizeType): void {
        super.resized(size);
        this._bg.height = size.height;
    }

    public forcedResize(): void {
        const size = {
            width: app.stage.canvas.width,
            height: app.stage.canvas.height,
        };
        this.resized(size);
    }

    public async despawnMenu() {
        let containerPromise;
        let framePromise;
        if (this._menuContainer) {
            containerPromise = this._menuContainer.animate().add(this._menuContainer, { alpha: 0 }, 0.3, tween.pow2Out);
        }
        if (this._menuFrame) {
            framePromise = this._menuFrame.animate().add(this._menuFrame, { alpha: 0 }, 0.3, tween.pow2Out);
        }
        await Promise.all([containerPromise, framePromise]);
        this._menuContainer?.removeSelf();
        this._menuFrame?.removeSelf();

        this._menuContainer = null;
        this._menuFrame = null;
    }

    // toggle from outside
    public spawnMenu(buttons: HelpType[], animate = false): ImageButton[] {
        const buttonMap = {
            health: {
                onPress: this.onTreat.bind(this),
                label: '[petTreat]',
            },
            nourish: {
                onPress: this.onForage.bind(this),
                label: '[petForage]',
            },
            train: {
                onPress: this.onTrain.bind(this),
                label: '[petTrain]',
            },
        };

        if (buttons.length === 0) return [];
        const frame = Sprite.from(manifest.buttonFrame);
        frame.alpha = 0.7;

        const buttonViews = [];
        const container = new Container();
        let x = 0;
        for (const id of buttons) {
            const button = new ImageButton({
                image: manifest[id],
            });

            container.addChild(button);
            button.x = x;
            x += button.width + 4;

            buttonViews.push(button);
            button.onPress = buttonMap[id].onPress;

            const label = new BasicText({
                text: buttonMap[id].label,
                style: {
                    fill: '#FFF5C1',
                    fontSize: 28,
                    stroke: 0x000,
                    strokeThickness: 4,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                },
            });
            button.addChild(label);
            uiAlignCenter(button, label, 0, 108);

            if (id === 'train') {
                // gray out, allow tap and trigger "check later" text
                const grayscale = new ColorMatrixFilter();
                grayscale.grayscale(0.4, false);
                button.filters = [grayscale];
            }
        }

        if (animate) {
            frame.alpha = 0;
            frame.animate().add(frame, { alpha: 1 }, 0.3, tween.pow2In);
            container.alpha = 0;
            container.animate().add(container, { alpha: 1 }, 0.3, tween.pow2In);
        }

        this._bg.addChild(frame, container);
        uiAlignBottom(this._bg, frame, -40);
        uiAlignCenterX(this._bg, frame);
        uiAlignBottom(this._bg, container, -60);
        uiAlignCenterX(this._bg, container);

        this._menuContainer = container;
        this._menuFrame = frame;

        // return in case spawned from flow
        return buttonViews;
    }

    public playPetAnimation(animation: FairyClipId, loop = false): Promise<void> {
        this._bg.sortableChildren = true;
        switch (animation) {
            case 'eat':
            case 'idle_happy':
            case 'idle_normal':
            case 'sad_to_happy':
                this._fairy.dust = true;
                break;
            default:
                this._fairy.dust = false;
        }

        return this._fairy.start(animation, loop);
    }

    public async spawnSpeechSequence(speechId: 'speech1' | 'speech2', promise?: NakedPromise) {
        this._isTalking = true;
        const speechMap = {
            speech1: {
                main: '[petTutorialMain0]',
                dialogOffset: 0,
                bubbleY: -150,
            },
            speech2: {
                main: '[petTutorialMain1]',
                dialogOffset: 3,
                bubbleY: -200,
            },
        };

        const views: Container[] = [];
        const speechEntry = speechMap[speechId];
        const topFrame = Sprite.from(manifest.frame);

        this._underlayInput = this._underlayInput ?? new TouchInputComponent(this.root);
        this._underlayInput.enabled = true;
        let finishedSpeech = false;
        this._underlayInput.onTap = async () => this._speechTap(views, speechId, finishedSpeech, promise);

        const textWidth = topFrame.width - 160;
        const mainLabel = new BasicText({
            text: speechEntry.main,
            style: {
                fill: '#FFF',
                fontSize: 32,
                lineJoin: 'round',
                wordWrap: true,
                lineHeight: 55,
                wordWrapWidth: textWidth,
            },
        });

        topFrame.addChild(mainLabel);
        uiAlignCenter(topFrame, mainLabel);
        uiSizeToFit(mainLabel, textWidth, topFrame.height - 100);

        this._bg.addChild(topFrame);

        views.push(topFrame);
        topFrame.y += 10;

        // bubbles
        const defaultX = this._pet.x - 360;
        const x = defaultX;
        let y = speechMap[speechId].bubbleY;
        for (let i = 0; i < 3; i++) {
            const bubble = Sprite.from(manifest.smallBubble);
            i === 1 ? (bubble.x = x + 50) : (bubble.x = x);
            bubble.y = y;
            bubble.zIndex = 6;
            this._pet.addChild(bubble);
            y += 170;

            if (i === 2) {
                const tail = Sprite.from(manifest.bubbleTail);
                bubble.addChild(tail);
                uiAlignCenter(bubble, tail, -15, 88);
            }

            const label = new BasicText({
                style: {
                    fill: '#000',
                    fontSize: 32,
                    lineJoin: 'round',
                },
            });

            bubble.addChild(label);
            const fullText = textLocaleFormat(`[petTutorialDialog${i + speechEntry.dialogOffset}]`);
            views.push(bubble);

            bubble.alpha = 0;
            bubble.animate().add(bubble, { alpha: 1 }, this._skipSpeech ? 0 : 0.3, tween.pow2In);
            await sleep(this._skipSpeech ? 0 : 0.5);

            for (let i = 0; i < fullText.length; i++) {
                label.text = fullText.substring(0, i + 1);
                uiAlignCenter(bubble, label);
                if (i % 2 === 0) this._playSpeechSound();

                await sleep(this._skipSpeech ? 0 : SPEECH_SPEED);
            }
        }

        finishedSpeech = true;
        this._skipSpeech = false;
        this._spawnTapContainer(views);
    }

    public async spawnBubbleTap(promise?: NakedPromise) {
        this._isTalking = true;

        const views: Container[] = [];
        this._underlayInput = this._underlayInput ?? new TouchInputComponent(this.root);
        this._underlayInput.enabled = true;
        let finishedSpeech = false;
        const onTap = async () => {
            if (!finishedSpeech) {
                this._skipSpeech = true;
                return;
            }

            for (const view of views) {
                view.animate()
                    .add(view, { alpha: 0 }, 0.3, tween.pow2Out)
                    .then(() => view.removeSelf());

                promise.resolve();
            }
        };

        // bubble spawn and tap
        const bubble = await this.spawnSpeechBubble('[petTutorialBubble6]', onTap);
        // this step requires tap to continue,
        // re-enable it even though it got disabled in the end of the shared bubble animation above
        this._underlayInput.enabled = true;
        finishedSpeech = true;
        views.push(bubble);
        this._spawnTapContainer(views);
    }

    public async setSetPetName(name: string): Promise<void> {
        await this._petName.animate().add(this._petName, { alpha: 0 }, 0.1, tween.linear);
        this._petName.text = name;
        uiAlignCenter(this._petName.parent, this._petName);
        uiSizeToFit(this._petName.parent, this._petName.parent.width, this._petName.parent.height);
        await this._petName.animate().add(this._petName, { alpha: 1 }, 0.25, tween.linear);
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn(options: PetScreenOptions) {
        // spawn scene
        this._bg = new NineSlicePlane(
            Texture.from(options?.initState === 'speech1' ? bgManifest.zoomedOut : bgManifest.default),
            0,
            0,
            0,
            1335,
        );
        this._bg.sortableChildren = true;
        this._bg.width = pixiConfig.size.width;
        this._bg.height =
            app.stage.canvas.height < pixiConfig.size.height ? pixiConfig.size.height : app.stage.canvas.height;

        if (options?.initState !== 'speech1') {
            // spaned later in new layout
            this._headerView = this._createHeader();
        }

        this.base.addContent({
            bg: {
                content: this._bg,
                styles: {
                    position: 'bottomCenter',
                },
            },
            header: {
                content: this._headerView,
                styles: {
                    position: 'topCenter',
                },
            },
        });

        // extra container, animated
        this._spawnPet(options?.initState);
    }

    private _spawnPet(initState?: InitState) {
        this._fairy = new Fairy({ container: () => this._bg });
        this.root.sortableChildren = true;
        const button = new ImageButton({
            sound: 'tap-pet.ogg',
        });
        button.width = 330;
        button.height = 430;
        this._fairy.zIndex = 4;
        button.zIndex = 6;
        const container = new Container();
        container.zIndex = 1;
        container.sortableChildren = true;
        container.addChild(button, this._fairy);

        uiAlignCenter(button, this._fairy, 0, 320);

        this._fairy.skin = 'fairy1';
        initState === 'speech1' ? this._fairy.start('init_injured', true) : this._fairy.start('idle_normal', true);
        if (initState !== 'speech1') this._fairy.dust = true;

        if (!initState) {
            button.onPress = this.onPetTap.bind(this);
        }

        this._bg.addChild(container);
        uiAlignBottom(this._bg, container, initState === 'speech1' ? -380 : -180);
        uiAlignCenterX(this._bg, container);
        this._pet = container;

        if (initState === 'speech1') this._fairy.scale.set(0.6);
    }

    private _createHeader() {
        const frame = Sprite.from(manifest.nameFrame);
        this._petName = new BasicText({
            text: app.game.player.name,
            style: {
                fill: '#FFF5C1',
                fontSize: 34,
                stroke: 0x000,
                strokeThickness: 4,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'center',
            },
        });

        frame.addChild(this._petName);
        uiAlignCenter(frame, this._petName);
        uiSizeToFit(this._petName, frame.width, frame.height);

        const statsContainer = new Container();

        this._healthProgress = new CircleProgress({ type: 'health' });
        this._foodProgress = new CircleProgress({ type: 'food' });
        this._trainProgress = new CircleProgress({ type: 'strength' });

        const grayscale = new ColorMatrixFilter();
        grayscale.grayscale(0.4, false);
        this._trainProgress.filters = [grayscale];

        statsContainer.addChild(this._healthProgress, this._foodProgress, this._trainProgress);

        this._healthProgress.setProgress(0.75);
        this._foodProgress.setProgress(0.75);
        this._trainProgress.setProgress(1);

        let x = 0;
        this._healthProgress.x = x;
        x += this._healthProgress.width + 50;
        this._foodProgress.x = x;
        x += this._foodProgress.width + 50;
        this._trainProgress.x = x;
        x += this._trainProgress.width + 50;

        const mainContainer = new Container();
        frame.x = -50;
        frame.y = 25;
        statsContainer.x = 180;
        statsContainer.y = 20;
        mainContainer.addChild(frame, statsContainer);
        return mainContainer;
    }

    private async _startPet() {
        await sleep(0.75);
        while (!this._isTalking) {
            // const prevX = this._pet.x;
            const padding = 250;
            const x = Math.round(Math.random() * (this._bg.width - padding) - this._pet.width * 0.5 + padding * 0.5);
            const y = Math.round(Math.random() * (this._bg.height - this._pet.height * 1.35));
            this._flyAnimation = this._pet.animate().add(this._pet.position, { x, y }, 4, tween.pow2InOut);

            // TODO FIX DIRECTION
            // this.playPetAnimation('move_normal', true)
            // prevX < x
            //     ? this._pet.scale.set(1, 1)
            //     : this._pet.scale.set(-1, 1);
            await this._flyAnimation;
        }
    }

    private async onPetTap() {
        if (this._underlayInput) this._underlayInput.enabled = false;
        if (this._isTalking) {
            this._skipSpeech = true;
            return;
        }

        this._isTalking = true;
        trackTapPet();

        if (this?._pointer) {
            this._pointerAnimation?.cancel();
            this._pointer
                .animate()
                .add(this._pointer.scale, { x: 0, y: 0 }, 0.2, tween.backIn(1.2))
                .then(() => this._pointer.removeSelf());
        }

        this._skipSpeech = false;
        const bubble = await this.spawnSpeechBubble();
        this._isTalking = false;
        await sleep(2);

        await bubble.animate().add(bubble, { alpha: 0 }, 0.3, tween.pow2Out);
        bubble.removeSelf();
        this._skipSpeech = false;
        this._startPet();
    }

    public async spawnSpeechBubble(overrideText?: string, onTapOverride?: () => Promise<any>) {
        this._flyAnimation?.cancel();

        if (this._pet.x !== this._bg.width * 0.5 - this._pet.width * 0.5 || this._pet.y !== this._bg.height - 680) {
            await this._pet
                .animate()
                .add(
                    this._pet.position,
                    { x: this._bg.width * 0.5 - this._pet.width * 0.5, y: this._bg.height - 680 },
                    0.75,
                    tween.pow2InOut,
                );
        }
        const bubble = new NineSlicePlane(Texture.from(manifest.bigBubble), 200, 0, 200, 0);
        bubble.width = 520;
        const tail = Sprite.from(manifest.bubbleTail);
        bubble.addChild(tail);
        uiAlignCenter(bubble, tail, -15, 120);

        const maxLabelWidth = bubble.width - 60;
        const label = new BasicText({
            style: {
                fill: '#000',
                fontSize: 27,
                lineJoin: 'round',
                wordWrap: true,
                wordWrapWidth: maxLabelWidth,
                align: 'center',
            },
        });

        if (this._petDialogs.length === 0) {
            this._petDialogs = arrayShuffle([...DEFAULT_DIALOGS]);
        }

        const speechDialog = this._petDialogs.shift();
        bubble.addChild(label);
        const fullText = overrideText ? textLocaleFormat(overrideText) : textLocaleFormat(speechDialog);
        this._pet.addChild(bubble);

        uiAlignCenter(this._pet, bubble, -130, -this._pet.height + 110);

        this._underlayInput = this._underlayInput ?? new TouchInputComponent(this.root);

        if (onTapOverride) {
            this._underlayInput.onTap = () => onTapOverride();
        } else {
            this._underlayInput.onTap = async () => (this._skipSpeech = true);
        }

        this._underlayInput.enabled = true;
        bubble.alpha = 0;
        bubble.animate().add(bubble, { alpha: 1 }, this._skipSpeech ? 0 : 0.3, tween.pow2In);
        await sleep(this._skipSpeech ? 0 : 0.5);

        for (let i = 0; i < fullText.length; i++) {
            label.text = fullText.substring(0, i + 1);
            uiSizeToFit(label, maxLabelWidth, bubble.height - 40);
            uiAlignCenter(bubble, label, 2);
            if (i % 2 === 0) this._playSpeechSound();

            await sleep(this._skipSpeech ? 0 : SPEECH_SPEED);
        }

        this._skipSpeech = false;
        this._underlayInput.enabled = false;
        return bubble;
    }

    private async onTreat() {
        await new PetHelpFlow({ petScreen: this, type: 'treat' }).execute();
    }

    private async onForage() {
        await new PetHelpFlow({ petScreen: this, type: 'forage' }).execute();
    }

    private async onTrain() {
        await this._showCheckLater();
    }

    private _playSpeechSound() {
        if (this._skipSpeech) return;
        app.sound.play(`speak${Math.round(Math.random() * 2)}.ogg`, { volume: 0.25, dupes: 2 });
    }

    private async _speechTap(
        views: Container[],
        speechId: 'speech1' | 'speech2',
        finishedSpeech: boolean,
        promise: NakedPromise,
    ) {
        if (!finishedSpeech) {
            this._skipSpeech = true;
            // allow curren running animation to finish with 0 delay
            return;
        }

        for (const view of views) {
            view.animate()
                .add(view, { alpha: 0 }, 0.3, tween.pow2Out)
                .then(() => view.removeSelf());
        }

        // Special transition
        if (speechId === 'speech1') {
            const duration = 1;
            this._bg.pivot.set(this._bg.width * 0.5, this._bg.height * 0.5);
            this._bg.position.set(this._bg.width * 0.5, this._bg.height * 0.5);
            this._bg.animate().add(this._bg, { alpha: 0 }, duration - 0.1, tween.linear);
            this._fairy.animate().add(this._fairy.scale, { x: 0.65, y: 0.65 }, duration, tween.linear);
            const animationPromise = this._bg.animate().add(this._bg.scale, { x: 1.2, y: 1.2 }, duration, tween.pow2In);
            const bgPromise = app.resource.loadAsset(bgManifest.default);
            // load during animation
            await Promise.all([animationPromise, bgPromise]);

            const children = this._bg.children;
            this._bg = new NineSlicePlane(Texture.from(bgManifest.default), 0, 0, 0, 1335); // Scale blue top pixels
            this._bg.width = pixiConfig.size.width;
            this._bg.height =
                app.stage.canvas.height < pixiConfig.size.height ? pixiConfig.size.height : app.stage.canvas.height;

            this._bg.addChild(...children);
            const oldBg = this._bg;
            oldBg.removeSelf();

            this.base.removeChildByID('bg');
            this.base.removeChildByID('header');
            this._headerView = this._createHeader();
            this.base.addContent({
                bg: {
                    content: this._bg,
                    styles: {
                        position: 'bottomCenter',
                    },
                },
                header: {
                    content: this._headerView,
                    styles: {
                        position: 'topCenter',
                    },
                },
            });

            // fade in after
            this.headerView.alpha = 0;

            this._fairy.scale.set(1);
            uiAlignBottom(this._bg, this._pet, -340);

            this._bg.alpha = 0;
            await this._bg.animate().add(this._bg, { alpha: 1 }, duration, tween.pow2Out);
        }
        // this._isTalking = false;
        promise?.resolve();
        this._underlayInput.enabled = false;
    }

    private _spawnTapContainer(views: Container[]) {
        const tapFrame = uiCreateQuad(0x0, 0.3, pixiConfig.size.width, 126);

        const tapLabel = new BasicText({
            text: `[tapToContinue]`,
            style: {
                fill: '#FFF',
                fontSize: 32,
                lineJoin: 'round',
                fontWeight: 'bold',
                stroke: 0x0,
                strokeThickness: 3,
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.6,
                dropShadowDistance: 2,
            },
        });

        views.push(tapFrame, tapLabel);

        this._bg.addChild(tapFrame, tapLabel);
        uiAlignBottom(this._bg, tapFrame);
        uiAlignBottom(this._bg, tapLabel, -35);
        uiAlignCenterX(this._bg, tapLabel);
    }

    private async _showCheckLater() {
        if (this._checkLater) return;
        this._checkLater = new BasicText({
            text: `[petCheckLater]`,
            style: {
                fill: '#FFF',
                fontSize: 40,
                lineJoin: 'round',
                fontWeight: 'bold',
                stroke: 0x0,
                strokeThickness: 4,
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.6,
                dropShadowDistance: 2,
            },
        });
        this._checkLater.pivot.set(this._checkLater.width * 0.5, this._checkLater.height * 0.5);
        this._checkLater.zIndex = 10;
        this._bg.addChild(this._checkLater);
        uiAlignCenter(this._bg, this._checkLater, 0, 70);
        this._checkLater.alpha = 0;
        await this._checkLater.animate().add(this._checkLater, { alpha: 1 }, 0.2, tween.pow2In);
        await sleep(1.75);
        await this._checkLater.animate().add(this._checkLater, { alpha: 0 }, 0.2, tween.pow2Out);
        this._checkLater.removeSelf();
        this._checkLater = null;
    }
}
