//TODO: rename to items.ts
import { SB } from '@play-co/replicant';
// state
//-----------------------------------------------------------------------------
const resourceSchema = SB.object({
    food: SB.int().min(0).default(6300),
    gems: SB.int().min(0).default(230),
    iron: SB.int().min(0),
    recruits: SB.int().min(0),
});

//TODO: ensure new entries here are are same as ItemType
export const inventoryState = {
    inventory: SB.object({
        // battle: SB.map(SB.int().min(0)),
        resources: resourceSchema,
        materials: SB.map(SB.int().min(0)),
        general: SB.map(SB.int().min(0)),
    }),
};
