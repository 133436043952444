import { action } from '@play-co/replicant';

import { MutableState, State, SyncActionAPI } from '../defs/replicant';

// actions
//-----------------------------------------------------------------------------
export const tutorialActions = {
    // pets
    tutorialPetsSetStep: action((state: MutableState, options: { step: number }) => {
        state.tutorial.pets.step = options.step;
    }),

    tutorialPetsComplete: action((state: MutableState, _, api: SyncActionAPI) => {
        state.tutorial.pets.complete = true;
    }),

    tutorialPetsReset: action((state: MutableState, _) => {
        state.tutorial.pets.complete = false;
        state.tutorial.pets.step = 0;
    }),
};

// api
//-----------------------------------------------------------------------------
export function isFullTutorialComplete(state: State | MutableState): boolean {
    return state.tutorial.pets.complete;
}
