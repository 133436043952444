//NOTE: keep this simple
import { Container, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import app from '../../../../index.entry';
import { Animation } from '../../../../lib/animator/Animation';
import { BasicAsyncHandler, RegionType, sizeOne, SizeType } from '../../../../lib/defs/types';
import { TouchInputComponent } from '../../../../lib/pixi/components/TouchInputComponent';
import { UberEffecs, UberMaterial } from '../../../../lib/pixi/materials/UberMaterial';
import { pixiSetEnabled } from '../../../../lib/pixi/pixiTools';
import { MaterialNineSlicePlane } from '../../../../lib/pixi/scene/MaterialNineSlicePlane';
import { MaterialSprite } from '../../../../lib/pixi/scene/MaterialSprite';
import { onDownAnimation, onTapAnimation, onUpAnimation } from '../../util/clickAnimation';

// types
//-----------------------------------------------------------------------------
export type ImageButtonOptions = {
    // primary image
    image?: string;
    // replacement image if in selected state
    selected?: string;
    // slicing options
    slice?: Partial<RegionType> & Partial<SizeType>;
    // shader effects
    effects?: UberEffecs;
    //TODO: remove
    bubble?: boolean;
    sound?: string; // override default sound
};

type ButtonType = Sprite | NineSlicePlane | MaterialSprite | MaterialNineSlicePlane;

/*
    ui: basic image button with optional nineslice support
*/
export class ImageButton extends Container {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onPress?: BasicAsyncHandler;
    public onDown?: BasicAsyncHandler;
    public onUp?: BasicAsyncHandler;
    // input
    private _options: ImageButtonOptions;
    private _enabled = true;
    private _selected = false;
    private _animated = true;
    // scene
    private _button: ButtonType;
    private _image: Sprite | NineSlicePlane | MaterialNineSlicePlane | MaterialSprite;
    private _sound = 'button.ogg';
    // components
    private _input: TouchInputComponent;
    private _animation: Animation;

    // properties
    //-------------------------------------------------------------------------
    public get animated(): boolean {
        return this._animated;
    }

    public set animated(animated: boolean) {
        this._animated = animated;
    }

    public get enabled(): boolean {
        return this._enabled;
    }

    public set enabled(enabled: boolean) {
        if (this._enabled !== enabled) {
            this._enabled = enabled;
            this._updateEnabled();
        }
    }

    public get selected(): boolean {
        return this._selected;
    }

    public set selected(selected: boolean) {
        if (this._selected !== selected) {
            this._selected = selected;
            this._updateSelected();
        }
    }

    public get button(): ButtonType {
        return this._button;
    }

    public get image(): Sprite | NineSlicePlane | MaterialNineSlicePlane | MaterialSprite {
        return this._image;
    }

    public get material(): UberMaterial {
        return (this._button as any).material as UberMaterial;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: ImageButtonOptions) {
        super();

        if (options.sound) this._sound = options.sound;

        // set fields
        this._options = options;

        // spawn
        this._spawn(options);

        // initialize
        this._input = Object.assign(new TouchInputComponent(this), {
            onTap: this._onTap.bind(this),
            onDown: this._onDown.bind(this),
            onUp: this._onUp.bind(this),
            bubble: !!options.bubble,
        });
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn(options: ImageButtonOptions) {
        // create texture
        const texture = options.image ? Texture.from(options.image) : Texture.EMPTY;
        let button: ButtonType;

        // if material sprite
        if (options.effects) {
            this._image = options.slice
                ? new MaterialNineSlicePlane(texture, options.slice, { ...sizeOne, ...options.slice }, options.effects)
                : new MaterialSprite(texture, options.effects);
            button = this._button = this.addChild(this._image);
        }
        // if nine slice
        else if (options.slice) {
            const slice = options.slice;
            this._image = new NineSlicePlane(texture, slice.left, slice.top, slice.right, slice.bottom);
            button = this._button = this.addChild(this._image).props({
                width: slice.width,
                height: slice.height,
            });
            // else sprite
        } else {
            this._image = new Sprite(texture);
            button = this._button = this.addChild(this._image);
        }

        // readjust anchor/pivot offset
        button.pivot.set(button.width / 2, button.height / 2);
        button.position = button.pivot;

        //TODO: temporary hack to fix missing input hitbox for MaterialSprite
        this.hitArea = {
            contains: (x, y) => x > 0 && y > 0 && x < this.width / this.scale.x && y < this.height / this.scale.y,
        };
    }

    // private: updaters
    //-------------------------------------------------------------------------
    protected _updateSelected() {
        // if supported, update selected image texture
        const image = this._options.selected;
        if (image) this._button.texture = Texture.from(this._selected ? image : this._options.image);
    }

    protected _updateEnabled() {
        // update visual
        const material = this.material;
        //TODO: hack
        if (material) material.saturation = this._enabled ? 1 : 0;
        else pixiSetEnabled(this, this._enabled);

        // update input
        this._input.enabled = this._enabled;
    }

    // private: handlers
    //-------------------------------------------------------------------------
    private async _onTap() {
        // play sound
        app.sound.play(this._sound, { dupes: 5, volume: 0.6 });

        if (this._animated) {
            this._animation?.cancel();
            this._animation = onTapAnimation(this._button);
        }

        // handle press
        await this.onPress?.();
    }

    private _onDown() {
        // animate
        if (this._animated) {
            this._animation?.cancel();
            this._animation = onDownAnimation(this._button);
        }

        // handle down
        this.onDown?.();
    }

    private _onUp() {
        // animate
        if (this._animated) {
            this._animation?.cancel();
            this._animation = onUpAnimation(this._button);
        }

        // handle up
        this.onUp?.();
    }
}
