import { SB } from '@play-co/replicant';

import { adminState } from './defs/admin';
import { battleState } from './defs/battle';
import { coreState } from './defs/core';
import { energyState } from './defs/energy';
import { heroesState } from './defs/heroes';
import { inventoryState } from './defs/inventory';
import { petState } from './defs/pets';
import { playerState } from './defs/player';
import { settingsState } from './defs/settings';
import { teamState } from './defs/team';
import { tutorialState } from './defs/tutorial';

// combined state
//-----------------------------------------------------------------------------
export const stateSchema = SB.object({
    ...adminState,
    ...battleState,
    ...coreState,
    ...energyState,
    ...heroesState,
    ...inventoryState,
    ...playerState,
    ...settingsState,
    ...petState,
    ...teamState,
    ...tutorialState,
});
