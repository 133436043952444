import app from '../../../index.entry';
import { SizeType } from '../../../lib/defs/types';
import { PerformanceMonitor } from '../../../lib/pixi/tools/PerformanceMonitor';
import { uiAlignRight } from '../../../lib/pixi/uiTools';
import { cheatLayout } from '../../defs/cheats';
import { testCheatLayout } from '../../defs/testCheats';
import { LayoutScreen } from '../../lib/screens/LayoutScreen';
import { TextSliceButton } from '../../lib/ui/buttons/TextSliceButton';
import { BasicText } from '../../lib/ui/text/BasicText';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    buttonBlue: 'button.blue.png',
};

/*
    cheat screen
*/
export class CheatScreen extends LayoutScreen {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _cheatButton: TextSliceButton;
    private _testButton: TextSliceButton;
    private _uiButton: TextSliceButton;
    private _debugText: BasicText;
    private _performanceText: BasicText;
    // components
    private _performanceMonitor = new PerformanceMonitor({ listener: this._onPerformanceUpdate.bind(this) });

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app.resource.loadAssets([...Object.values(manifest)]);
    }

    public async spawning() {
        // spawn scene
        this._spawn();

        // start performance monitor
        this._performanceMonitor.start();
    }

    public despawned() {
        // stop performance monitor
        this._performanceMonitor.stop();

        // empty scene
        this.empty();
    }

    public override resized(size: SizeType): void {
        super.resized(size);
    }

    // api
    //-------------------------------------------------------------------------
    public setDebug(text: string) {
        const debug = this._debugText;
        if (debug) debug.text = text;
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn() {
        // spawn scene
        this._cheatButton = this._spawnButtons('Cheat');
        this._cheatButton.onPress = () => this._onOpenCheatMenu();
        this._testButton = this._spawnButtons('Tests');
        this._testButton.onPress = () => this._onOpenTestMenu();

        this.root.addContent({
            cheat: {
                content: this._cheatButton,
                styles: {
                    position: 'leftCenter',
                    marginTop: -this._cheatButton.width,
                    marginLeft: this._cheatButton.height,
                },
            },
            test: {
                content: this._testButton,
                styles: {
                    position: 'leftCenter',
                    marginLeft: this._testButton.height,
                },
            },
            battle: {
                content: this._uiButton,
                styles: {
                    position: 'leftCenter',
                    marginTop: this._cheatButton.width,
                    marginLeft: this._testButton.height,
                },
            },
        });

        // debug text
        this._debugText = this.root.addChild(
            new BasicText({
                style: {
                    fontSize: 24,
                    fontWeight: 'bold',
                    fill: '#fff',
                    strokeThickness: 2,
                },
            }),
        );

        // performance text
        this._performanceText = this.root.addChild(
            new BasicText({
                style: {
                    fontSize: 24,
                    fontWeight: 'bold',
                    fill: '#f08',
                    strokeThickness: 2,
                },
            }),
        );
    }

    private _spawnButtons(text: string, width?: number) {
        return new TextSliceButton({
            text,
            animate: true,
            slice: {
                sliceUp: manifest.buttonBlue,
                sliceDown: manifest.buttonBlue,
                width: width || 120,
                height: 50,
                left: 45,
                top: 0,
                right: 45,
                bottom: 0,
            },
            style: {
                fill: '#fff4e6',
                strokeThickness: 2,
                fontSize: 28,
                fontWeight: 'bold',
                lineJoin: 'round',
            },
        }).props({
            anchor: { x: 0.5, y: 0.5 },
            rotation: Math.PI / 2,
        });
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onPerformanceUpdate() {
        this._performanceText.text = this._performanceMonitor.fps.toFixed(1);
        uiAlignRight(this.root, this._performanceText);
    }

    private async _onOpenCheatMenu() {
        await app.nav.open('cheatPopup', {
            title: 'Cheats',
            layout: cheatLayout,
            onClose: () => this._onCloseCheatPopup(),
        });
    }

    private async _onOpenTestMenu() {
        await app.nav.open('cheatPopup', {
            title: 'AB Tests',
            layout: testCheatLayout,
            onClose: () => this._onCloseCheatPopup(),
        });
    }

    private async _onCloseCheatPopup() {
        app.nav.close('cheatPopup');
    }
}
