import { Sprite } from 'pixi.js';

import app from '../../../index.entry';
import { ILoader } from '../../../plugins/nav/ILoader';
import { pixiConfig } from '../../defs/config';
import { LayoutScreen } from '../../lib/screens/LayoutScreen';
import { LoadingProgessBar } from '../../lib/ui/progress/LoadingProgessBar';
import { fakeProgress } from './SplashLoader';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bg: 'bg.pets.splash.png',
};

/*
    nav: major scene loader screen
*/
export class MajorLoader extends LayoutScreen implements ILoader {
    // fields
    //-------------------------------------------------------------------------
    private _progress: LoadingProgessBar;
    private _fakeRatio = 1;

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app.resource.loadAssets([...Object.values(manifest), ...LoadingProgessBar.assets()]);
    }

    public async init() {
        // progress
        const progress = (this._progress = new LoadingProgessBar({
            width: 540,
        }));

        // layout
        this.root.addContent({
            bg: {
                content: Sprite.from(manifest.bg),
                styles: {
                    position: 'center',
                    width: pixiConfig.size.width,
                    height: pixiConfig.size.height,
                    minWidth: '100%',
                    minHeight: '100%',
                },
            },
            progress: {
                content: progress,
                styles: {
                    position: 'bottomCenter',
                    scale: 0.8,
                    marginBottom: 150,
                },
            },
        });
    }

    public async spawning() {
        // reset preogress
        this._fakeRatio = Math.max(1 - fakeProgress, 0);
        this._progress.setProgress(fakeProgress, false);
    }

    public progress(ratio: number): void {
        // update progress
        this._progress.setProgress(fakeProgress + ratio * this._fakeRatio, true);
    }
}
