import { SB } from '@play-co/replicant';

// state
//-----------------------------------------------------------------------------
// world-, boss-, raid energy
export const energyState = {
    energy: SB.object({
        world: SB.object({
            // Never access the 'count' directly, use 'getWorldEnergy' to take regen into consideration
            count: SB.int().default(2), // gets reset again after boss kill
            time: SB.int().default(0),
            //TODO: dont use long redundant names like this. it should be: fullTime
            energyFullTimestamp: SB.int().default(0), // Last time sent energy full
        }),
        raid: SB.object({
            count: SB.int().default(0),
            time: SB.int().default(0),
        }),
    }),
};
