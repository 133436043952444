import { ISlot, Spine, TrackEntry } from '@pixi-spine/all-4.1';
import { Assets, Container, DisplayObject, Rectangle, Texture } from 'pixi.js';

import { IAnimation } from '../../pattern/IAnimation';

// types
//-----------------------------------------------------------------------------
export type SpineAnimationOptions = {
    // asset json
    json: string;
};

/*
    generalized wrapper for Spine animations
*/
export class SpineAnimation extends Spine implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onComplete?: (id: string) => void;
    // state
    private _activeId: string;

    // properties
    //-------------------------------------------------------------------------
    public get activeId(): string {
        return this._activeId;
    }

    public set skin(id: string) {
        const skeleton = this.skeleton;
        skeleton.setSkin(null);
        if (id) skeleton.setSkinByName(id);
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: SpineAnimationOptions) {
        super(Assets.get(options.json));

        // register events
        this.state.addListener({
            complete: (entry: TrackEntry) => this.onComplete?.(entry.animation.name),
        });
    }

    // impl
    //-------------------------------------------------------------------------
    public async start({ id, mix = 0, loop = false }: { id: string; mix?: number; loop?: boolean }) {
        // local animation by given id
        const animation = this.spineData.findAnimation(id);
        if (animation) {
            if (mix && this._activeId) {
                // enable mixing if requested and previous animation exists
                this.stateData.setMix(this._activeId, id, mix);
            }
        }

        // update active animation id
        this._activeId = id;

        // update active animation
        this.state.setAnimation(0, animation.name, loop);

        await new Promise((resolve) => (this.onComplete = resolve));
    }

    public stop() {
        //TODO
    }

    public duration(id: string) {
        return this.spineData.findAnimation(id).duration;
    }

    // api
    //-------------------------------------------------------------------------
    /*
        attaches something to a part of the spine
        id: the empty region attachment id as set by the artist
    */
    public attach(id: string, object: DisplayObject): boolean {
        return !!this._accessAttachable(id)?.addChild(object);
    }

    /*
        deataches something added with "attach"
        id: the empty region attachment id as set by the artist
    */
    public detach(id: string, object: DisplayObject): boolean {
        return !!this._accessAttachable(id)?.removeChild(object);
    }

    /*
        deataches everything added with "attach"
        id: the empty region attachment id as set by the artist
    */
    public detachAll(id: string) {
        this._accessAttachable(id)?.removeChildren();
    }

    // private: support
    //-------------------------------------------------------------------------
    private _accessAttachable(id: string): Container | undefined {
        // locate slot for given id
        const slotIndex = this.skeleton.findSlotIndex(id);
        if (slotIndex >= 0) {
            const slot = this.skeleton.slots[slotIndex] as ISlot;

            // require has an active base container
            if (slot.currentSprite instanceof Container) {
                // initialize for attaching if not already done
                if (!slot.hackAttachment) {
                    this.hackTextureBySlotIndex(slotIndex, Texture.EMPTY, new Rectangle(0, 0, 1, 1));
                }

                // return this container
                return slot.currentSprite;
            }
        }

        return undefined;
    }
}
