import { GCInstant } from '@play-co/gcinstant';

import app from '../../index.entry';
import { CooldownID, cooldowns } from '../../replicant/defs/cooldowns';
import gameConfig from '../../replicant/defs/gameConfig';
import { Player } from '../../replicant/objects/Player';

/*
    implementation of the active user player
*/
export class UserPlayer implements Player {
    // properties
    //-------------------------------------------------------------------------
    public get id(): string {
        return GCInstant.playerID;
    }

    public get image(): string {
        return GCInstant.playerPhoto || gameConfig.person.defaultImage;
    }

    public get name(): string {
        return app.server.state.name;
    }

    // public set name(name: string) {
    //     InstantGame.replicant.invoke.playerSetName({ name });
    // }

    public get time(): number {
        return 0;
        //return app.server.state.enterTime;
    }

    public get level(): number {
        //TODO
        return 0;
    }

    public isCooldownFinished(cooldownId: CooldownID): boolean {
        const cooldownStartTimestamp = app.server.state.cooldowns[cooldownId]?.startTimestamp || 0;
        const cooldownDuration = cooldowns[cooldownId].duration;
        return cooldownStartTimestamp + cooldownDuration < app.server.now();
    }
}
