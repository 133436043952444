import { SB } from '@play-co/replicant';

export type AdminItem = SB.ExtractType<typeof adminItemSchema>;

const adminItemSchema = SB.object({
    type: SB.string(), // resource, material, hero, etc
    id: SB.string(), // iron, food, specific heroId etc
    amount: SB.int(),
});

// state
//-----------------------------------------------------------------------------
export const adminState = {
    adminMessages: SB.array(adminItemSchema),
};
