import { createChatbotConfig, createChatbotEvents, LineWebhookEvent } from '@play-co/replicant';

import { messages } from '../messages';
import { scheduledActions } from '../scheduledActions';
import { stateSchema } from '../state';
import { chatbotMessageTemplates } from './messageTemplates';

// Also, define how to respond to webhook events (onGameEnd)
const events = createChatbotEvents(stateSchema, { messages, scheduledActions })({
    enabledWebhookEvents: ['follow'],
    onWebhook: async (state, messageEvent: LineWebhookEvent, api) => {},
});

// make sure to add the chatbot configuration the our config.ts
const chatbot = createChatbotConfig(chatbotMessageTemplates, events);

export default chatbot;
