//TODO: this should be called progress or world. battles are just a part of progression and not limited to it (pvp).
import { SB } from '@play-co/replicant';

// world-, boss-, raid battle
// state
//-----------------------------------------------------------------------------
export const battleState = {
    battle: SB.object({
        startedTimestamp: SB.int().default(-1), // shared battle timestamp, used for measurement and anti cheat
        world: SB.object({
            // real time battle, used to allow for replay and verify progress
            current: SB.object({
                region: SB.int().default(-1),
                stage: SB.int().default(-1),
            }),

            // campaign progress
            progress: SB.object({
                region: SB.int().min(0),
                stage: SB.int().min(0),
            }),
            meta: SB.map(
                SB.object({
                    attempts: SB.int().default(0),
                }),
            ),
            // For analytics
            won: SB.int().default(0),
            lost: SB.int().default(0),
            fled: SB.int().default(0),
        }),
    }),
};
