import { createScheduledActions } from '@play-co/replicant';

import { asyncGetters } from './asyncGetters';
import { coreScheduledActions, coreScheduledActionsSchema } from './components/core';
import { petScheduledActions, petScheduledActionsSchema } from './components/pets';
import { computedProperties } from './computedProperties';
import { messages } from './messages';
import { stateSchema } from './state';

export const scheduledActions = createScheduledActions(
    stateSchema,
    {
        ...coreScheduledActionsSchema,
        ...petScheduledActionsSchema,
    },
    {
        asyncGetters,
        computedProperties,
        messages,
    },
)({
    ...coreScheduledActions,
    ...petScheduledActions,
});
