import { Container } from 'pixi.js';

import { SpineAnimation } from '../../lib/pixi/animation/SpineAnimation';
import { ParticleEmitter } from '../../lib/pixi/particles/ParticleEmitter';
import { tween } from '../../lib/util/tweens';

// types
//-----------------------------------------------------------------------------
// options
export type FairyOptions = {
    container: () => Container;
};

// skin
export const fairySkinIds = ['fairy1', 'fairy2', 'fairy3'] as const;
export type FairySkinId = (typeof fairySkinIds)[number];

// clip
export const fairyClipIds = [
    'eat',
    'idle_happy',
    'idle_happy_B',
    'idle_hungry',
    'idle_normal',
    'idle_oneloop',
    'idle_sad',
    'init_injured',
    'init_injured_to_idle',
    'move_happy',
    'move_happy_B',
    'move_normal',
    'sad_to_happy',
] as const;
export type FairyClipId = (typeof fairyClipIds)[number];

// constants
//-----------------------------------------------------------------------------
const manifest = {
    spine: 'spine.fairy.json',
    sparkle: 'vfx.star.png',
};

/*
    concept: fairy
*/
export class Fairy extends Container {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _options: FairyOptions;
    // scene
    private _spine: SpineAnimation;
    private _sparkle: ParticleEmitter;
    // state
    private _dust = false;

    // properties
    //-------------------------------------------------------------------------
    public set skin(id: FairySkinId) {
        this._spine.skin = id;
    }

    public set dust(dust: boolean) {
        if (dust !== this._dust) {
            this._dust = dust;
            this._udpateDust();
        }
    }

    // init
    //-------------------------------------------------------------------------
    static assets() {
        return Object.values(manifest);
    }

    constructor(options: FairyOptions) {
        super();
        this._options = options;

        // spawn
        this._spawn();
    }

    // api
    //-------------------------------------------------------------------------
    public async start(id: FairyClipId, loop = false) {
        await this._spine.start({ id, loop, mix: 0.2 });
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn() {
        const spine = (this._spine = new SpineAnimation({ json: manifest.spine }));
        spine.scale.set(0.35);
        this.addChild(spine);
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private async _udpateDust() {
        const attachId = 'attach_body';
        // addsparkles
        if (this._dust) {
            this._spine.attach(
                attachId,
                (this._sparkle = new ParticleEmitter({
                    parent: this._options.container(),
                    textures: [manifest.sparkle],
                    rate: 0.08,
                    scale: [0.1, 0.6],
                    duration: 1.5,
                    behaviors: [
                        { type: 'drag', drag: 1.5 },
                        { type: 'gravity', gravity: { x: 0, y: -120 } },
                        { type: 'explode', magnitude: [140, 360] },
                        { type: 'kinematic' },
                        { type: 'fade', to: 0, tween: tween.pow2In },
                    ],
                })
                    .props({ x: 200 })
                    .temporal()),
            );
        } else if (this._sparkle) {
            this._sparkle.stop();
        }
    }
}
