import { LoaderDefs } from '../../plugins/nav/LoaderManager';
import { ScreenDefs } from '../../plugins/nav/NavPlugin';
import { AlphaTransition } from '../../plugins/nav/transitions/AlphaTransition';
import { AlertPopup } from '../lib/ui/popups/AlertPopup';
import { InfoPopup } from '../lib/ui/popups/InfoPopup';
import { PetHelpPopup } from '../lib/ui/popups/PetHelpPopup';
import { PromptPopup } from '../lib/ui/popups/PromptPopup';
import { CheatMenu } from '../world/cheat/CheatMenu';
import { CheatScreen } from '../world/cheat/CheatScreen';
import { MajorLoader } from '../world/loaders/MajorLoader';
import { SpinnerLoader } from '../world/loaders/SpinnerLoader';
import { SplashLoader } from '../world/loaders/SplashLoader';
import { PetNamePopup } from '../world/pet/PetNamePopup';
import { PetScreen } from '../world/pet/PetScreen';
import { SettingsPopup } from '../world/settings/SettingsPopup';
import { TipScreen } from '../world/tip/TipScreen';

// config
//-----------------------------------------------------------------------------
export enum NavLayer {
    main,
    menu,
    popup,
    tip,
    cheat,
    cheatPopup,
    count,
}

export const navLoaders: LoaderDefs = {
    splash: SplashLoader,
    boot: MajorLoader,
    default: SpinnerLoader,
};

// screens
//-----------------------------------------------------------------------------
export const navScreens: ScreenDefs = {
    alertPopup: {
        screen: AlertPopup,
        layer: NavLayer.popup,
    },
    cheatScreen: {
        screen: CheatScreen,
        layer: NavLayer.cheat,
    },
    cheatPopup: {
        screen: CheatMenu,
        layer: NavLayer.cheatPopup,
    },
    infoPopup: {
        screen: InfoPopup,
        layer: NavLayer.popup,
    },
    petHelpPopup: {
        screen: PetHelpPopup,
        layer: NavLayer.popup,
    },
    petScreen: {
        screen: PetScreen,
        transition: AlphaTransition,
        layer: NavLayer.main,
    },
    petNamePopup: {
        screen: PetNamePopup,
        layer: NavLayer.popup,
    },
    promptPopup: {
        screen: PromptPopup,
        layer: NavLayer.popup,
    },
    settingsPopup: {
        screen: SettingsPopup,
        layer: NavLayer.popup,
    },
    tipScreen: {
        screen: TipScreen,
        layer: NavLayer.tip,
    },
};
