import app from '../../index.entry';
import { FirstSessionAssetKey, firstSessionOA } from '../../replicant/chatbot/messageTemplates';
import { sleep } from '../../replicant/util/jsTools';
import { windowReload } from '../lib/util/device';
import { CheatLayout, CheatMenuApi } from '../world/cheat/CheatMenu';

// layout
//-----------------------------------------------------------------------------
export const cheatLayout: CheatLayout = [
    // actions
    [
        // info
        [
            {
                type: 'label',
                label: `v${process.env.VERSION}-${process.env.CDN_DEPLOYMENT_STAGE || 'dev'}`,
            },
        ],
        [
            // home
            {
                type: 'button',
                label: 'Home',
                onPress: onHome,
            },
            // reset
            {
                type: 'button',
                label: 'Reset',
                onPress: onReset,
            },
        ],
        [
            {
                type: 'button',
                label: 'Reset Skip Tut.',
                onPress: onResetSkipTutorial,
            },
        ],
        [
            {
                type: 'label',
                label: 'Locale',
            },
            {
                type: 'button',
                label: 'EN',
                onPress: onEN,
            },
            {
                type: 'button',
                label: 'JP',
                onPress: onJP,
            },
        ],
        [
            {
                type: 'label',
                label: 'Send OA',
            },
            {
                type: 'button',
                label: 'TestOA',
                onPress: onSendFirstSessionOAs,
            },
        ],
        // ------------------------------
    ],
];

// events
//-----------------------------------------------------------------------------
async function onHome(api: CheatMenuApi) {
    // close cheat menu
    api.close();
    // navigate home
    app.nav.open('petScreen');
}

async function onReset() {
    // reset backend state
    await app.server.invoke.coreReset();

    // reload game
    windowReload();
}

async function onSendFirstSessionOAs() {
    console.log(`-- onSendFirstSessionOAs`);
    for (const id of Object.keys(firstSessionOA) as FirstSessionAssetKey[]) {
        await app.server.invoke.sendFirstSessionOA({ id });
        console.log(`-- sent ${id}`);
        await sleep(1);
    }
}

async function onResetSkipTutorial() {
    // reset backend state
    await app.server.invoke.coreReset();

    await app.server.invoke.tutorialPetsComplete();

    // reload game
    windowReload();
}

async function onEN() {
    await app.settings.setLanguage('en');
    windowReload();
}

async function onJP() {
    await app.settings.setLanguage('ja');
    windowReload();
}
