//TODO: support some limit (count or time) to pending messages
import { arrayRemove, arrayReverseIterate, objectAccess } from '../../replicant/util/jsTools';
import NakedPromise from './NakedPromise';

// types
//-----------------------------------------------------------------------------
export type MessageListener<ID extends string, MESSAGE extends { id: ID }> = (message?: MESSAGE) => void;

/*
    basic pubsub keyed by message id
*/
export default class MessageBox<ID extends string, MESSAGE extends { id: ID }> {
    // fields
    //-------------------------------------------------------------------------
    private _listeners: Record<string, MessageListener<ID, MESSAGE>[]> = {};

    // api
    //-------------------------------------------------------------------------
    publish(message: MESSAGE) {
        const listeners = this._listeners[message.id];

        // notify listeners. reverse to allow safe listener removal
        if (listeners) arrayReverseIterate(listeners, (listener) => listener(message));
    }

    subscribe(id: ID, listener: MessageListener<ID, MESSAGE>) {
        // add listener
        objectAccess(id, this._listeners, () => []).push(listener);
    }

    unsubscribe(id: ID, listener: MessageListener<ID, MESSAGE>) {
        // remove listener
        arrayRemove(this._listeners[id], listener);
    }

    async waitForMessage(id: ID): Promise<MESSAGE> {
        const promise = new NakedPromise<MESSAGE>();
        const handler = (message: MESSAGE) => promise.resolve(message);

        // subscribe
        this.subscribe(id, handler);

        // wait for message
        const message = await promise;

        // unsubscribe
        this.unsubscribe(id, handler);

        return message;
    }
}
