import { Plugin, PluginOptions } from '@play-co/astro';

import app from '../../index.entry';
import { NavEvent, ScreenId } from '../../plugins/nav/NavPlugin';
import { trackScreenClose, trackScreenOpen } from '../lib/analytics/core';

// types
//-----------------------------------------------------------------------------
export type AnalyticsServiceOptions = PluginOptions;

/*
    monitors various events and forwards to analytics
*/
export class AnalyticsService extends Plugin {
    // fields
    public sessionTaps = 0;
    //-------------------------------------------------------------------------
    // private _stateObserver = new UpdateObserver();

    // init
    //-------------------------------------------------------------------------
    public init(): void {
        // subscribe to pubsub
        app.nav.events.subscribe(this._onNavEvent.bind(this));
    }

    public async start() {
        // observe state to update properties
    }

    // private: events
    //-------------------------------------------------------------------------
    // nav
    private _onNavEvent(event: NavEvent) {
        // handle screen open/close
        if (event.screenId) {
            if (event.id === 'spawned') this._onNavScreenOpen(event.screenId, event.options);
            else if (event.id === 'despawned') this._onNavScreenClose(event.screenId);
        }
    }

    private _onNavScreenOpen(id: ScreenId, options?: object) {
        trackScreenOpen({ id });
    }

    private _onNavScreenClose(id: ScreenId) {
        trackScreenClose({ id });
    }
}
