import { Plugin, PluginOptions } from '@play-co/astro';

import app from '../../index.entry';
import { App } from '../App';

// types
//-----------------------------------------------------------------------------
export type UiServiceOptions = PluginOptions;

/*
    general ui service
*/
export class UiService extends Plugin {
    // init
    //-------------------------------------------------------------------------
    constructor(app: App, options: Partial<UiServiceOptions>) {
        super(app, options);
    }

    // api
    //-------------------------------------------------------------------------
    // common prompts
    public async confirmSpendGems(cost: number): Promise<boolean> {
        const popup = 'spendConfirmPopup';
        return new Promise((resolve) =>
            app.nav.open(popup, {
                cost,
                have: app.server.state.inventory.resources.gems,
                onOk: () => resolve(true),
                onClose: async () => {
                    await app.nav.close(popup);
                    resolve(false);
                },
            }),
        );
    }
}
