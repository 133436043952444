// const PROD = 'https://fairy.playco.games/';
// const DEV = 'https://pets-concept-dev.cdn.gc-internal.net';
const PROD = 'https://liff.line.me/2000428350-pj2QayNJ';
const DEV = 'https://liff.line.me/2000427333-3VQbANdd';
const ALPHA = 'https://pets-concept-alpha.cdn.gc-internal.net';
const LOCAL = 'https://localhost:8052/';

export function getGameUrl() {
    let url = LOCAL; // No stage -> local dev.
    if (process.env.STAGE === 'prod') url = PROD;
    if (process.env.STAGE === 'dev') url = DEV;
    if (process.env.STAGE === 'alpha' || process.env.STAGE === 'bravo' || process.env.STAGE === 'charlie') url = ALPHA;

    return url;
}
