import { SB } from '@play-co/replicant';

export const heroSchema = SB.object({
    id: SB.string(),
    xp: SB.int().min(0),
    level: SB.int().min(0),
    // ascend: SB.int().min(0),
});

export const heroesState = {
    heroes: SB.map(heroSchema),
};
