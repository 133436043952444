import { Plugin, PluginOptions } from '@play-co/astro';
import * as lodash from 'lodash';

import app from '../../index.entry';
import { InstantGame } from '../../plugins/instantGames/InstantGame';
import { Player } from '../../replicant/objects/Player';
import { timeFromComponents } from '../../replicant/util/timeTools';
import { App } from '../App';
import { UserPlayer } from '../objects/UserPlayer';

// types
//-----------------------------------------------------------------------------
export type PlayerServiceOptions = PluginOptions;

// settings
//-----------------------------------------------------------------------------
const idleInterval = timeFromComponents({ seconds: 20 });

/*
    general game management service
*/
export class GameService extends Plugin {
    // fields
    private _idleInterval: ReturnType<typeof setInterval>;
    //-------------------------------------------------------------------------
    // state
    private readonly _player: UserPlayer;

    // properties
    //-------------------------------------------------------------------------
    public get player(): UserPlayer {
        return this._player;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(app: App, options: Partial<PlayerServiceOptions>) {
        super(app, options);

        // create user player
        this._player = new UserPlayer();
    }

    public async init() {}

    public async start() {
        const platform = InstantGame.platform;

        // run backend init
        await app.server.invoke.coreInit({
            entryData: lodash.omitBy(platform.entryData, lodash.isUndefined),
        });

        // run tasks
        this._runIdleTask();
    }

    public pauseIdleStepper() {
        clearInterval(this._idleInterval);
    }

    public startIdleStepper() {
        clearInterval(this._idleInterval);
        this._runIdleTask();
    }

    // api
    //-------------------------------------------------------------------------
    // social
    public async getPlayersWithIds(ids: string[]): Promise<Player[]> {
        // get server players
        return InstantGame.replicant.asyncGetters.playerGetPlayers({ ids });
    }

    // messages
    public async consumeMessages(ids: number[]) {
        //return InstantGame.replicant.invoke.mailConsume({ ids });
    }

    // private: tasks
    //-------------------------------------------------------------------------
    private _runIdleTask() {
        const task = () => {
            InstantGame.replicant.invoke.coreIdleStep({
                interval: idleInterval,
            });
        };
        task();
        this._idleInterval = setInterval(task, idleInterval);
    }
}
