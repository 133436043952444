//TODO: move all analytics tracking here
import { Plugin, PluginOptions } from '@play-co/astro';

import type { App } from '../App';

// types
//-----------------------------------------------------------------------------
// public
export type TrackingServiceOptions = PluginOptions;

/*
    tracks various game events and reports them to analytics
*/
export class TrackingService extends Plugin {
    // fields
    //-------------------------------------------------------------------------
    /*
    private _messageMap: { [key in MessageId]?: MessageListener<Message> } = {
        puzzleComplete: this._onPuzzleComplete,
    };
    */

    // init
    //-------------------------------------------------------------------------
    constructor(app: App, options: Partial<TrackingServiceOptions>) {
        super(app, options);
    }

    public init(): void {
        // subscribe to pubsub
        //app.core.messages.subscribe((message) => this._messageMap[message.id]?.call(this, message));
    }

    // private: events
    //-------------------------------------------------------------------------
    /*
    private _onPuzzleComplete(message: PuzzleCompleteMessage) {
        const player = app.game.player;
        const controller = message.controller;
        const { usedBoosters } = controller;

        // track puzzle complete
        trackPuzzleComplete({
            puzzleLevel: controller.mapLevel,
            result: message.result,
            puzzleDuration: app.server.now() - controller.time,
            successMoves: controller.moveCounter,
            limitMoves: controller.map.moves,
            usedBoosterRocket: 0,
            usedBoosterBomb: 0,
            usedBoosterRubik: 0,
            usedDart: usedBoosters.dart,
            usedBullet: usedBoosters.bullet,
            usedDrill: usedBoosters.drill,
            usedRoulette: usedBoosters.roulette,
            usedContinue: controller.continueCount,
            treasureId: message.complete?.grantedItem || 0,
            treasureGiftboxSent: controller.giftCount > 0,
            attack: controller.attackCount > 0,
            maxLeaguePuzzle: isMaxLeagueActive(InstantGame.replicant.state),
        });

        // if puzzle mission completed, track that too
        if (message.complete?.missionCompleted) {
            trackPuzzleMissionComplete({
                missionLevel: player.collectionLevel,
            });
        }
    }
    */
}
