import { SB } from '@play-co/replicant';

// state
//-----------------------------------------------------------------------------
export const playerState = {
    // general
    name: SB.string().default(''),
    // progression
    xp: SB.int(),
    level: SB.int(),
};
