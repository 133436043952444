import { Container, Graphics, LINE_CAP, Sprite, Texture } from 'pixi.js';

import { Color } from '../../lib/pixi/Color';
import { MaterialSprite } from '../../lib/pixi/scene/MaterialSprite';
import { uiAlignCenter, uiAlignCenterX } from '../../lib/pixi/uiTools';
import { BasicText } from '../lib/ui/text/BasicText';

// types
//-----------------------------------------------------------------------------
// public
export type CircleProgressType = 'food' | 'health' | 'strength';
export type CircleProgressOptions = {
    type: CircleProgressType;
};

// private
type Props = {
    icon: string;
    color: number;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bg: 'progress.circle.bg.png',
    fill: 'progress.circle.fill.png',
};

const propsMap: Record<CircleProgressType, Props> = {
    food: {
        icon: 'icon.apple.png',
        color: 0x75d22c,
    },
    health: {
        icon: 'icon.heart.png',
        color: 0xd22c4d,
    },
    strength: {
        icon: 'icon.weight.png',
        color: 0xc64fff,
    },
};

/*
    ui: circle progress bar
*/
export class CircleProgress extends Container {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _options: CircleProgressOptions;
    // scene
    private _mask0: Graphics;
    private _text: BasicText;
    // state
    private _progress: number;

    public get progress() {
        return this._progress;
    }

    // init
    //-------------------------------------------------------------------------
    static assets(options: Partial<CircleProgressOptions>) {
        return [...Object.values(manifest), propsMap[options.type].icon];
    }

    static assetsAll() {
        return [
            ...Object.values(manifest),
            ...Object.keys(propsMap).map((key: CircleProgressType) => propsMap[key].icon),
        ];
    }

    constructor(options: CircleProgressOptions) {
        super();

        // setup
        this._options = options;

        // spawn
        this._spawn();
    }

    // api
    //-------------------------------------------------------------------------
    public async setProgress(progress: number, animate = true) {
        //TODO: support animate
        this._progress = progress;
        this._update();
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _update() {
        this._updateText();
        this._updateMask();
    }

    private _updateMask() {
        const begin = Math.PI * 0.75;
        const distance = Math.PI * 1.5;
        const size = this.width / 2;

        // update mask
        const mask = this._mask0
            .clear()
            .lineStyle({
                width: 14,
                color: 0xffffff,
                cap: LINE_CAP.BUTT,
                alpha: 1,
            })
            .arc(0, 0, 31, begin, begin + distance * this._progress)
            .endFill();

        // align
        mask.x = size;
        mask.y = size;
    }

    private _updateText() {
        // update text
        this._text.text = `${Math.ceil(this._progress * 100)}%`;

        // align
        uiAlignCenterX(this, this._text);
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn() {
        const props = propsMap[this._options.type];
        const hsl = Color.from(props.color).toHsl();
        hsl.saturation = 1;
        hsl.luminance = 1;

        // spawn bg
        this.addChild(Sprite.from(manifest.bg));

        // spawn mask
        const mask = (this._mask0 = this.addChild(new Graphics()));

        // spawn fill
        const fill = this.addChild(
            new MaterialSprite(Texture.from(manifest.fill), { hsl: { hsl } }).props({
                y: 10,
                mask,
            }),
        );
        uiAlignCenterX(this, fill);

        // spawn icon
        const icon = this.addChild(Sprite.from(props.icon));
        uiAlignCenter(this, icon);

        // spawn text
        this._text = this.addChild(
            new BasicText({
                style: {
                    fill: 0xffffff,
                    fontWeight: 'bold',
                    fontSize: 17,
                },
            }).props({ y: 64 }),
        );
    }
}
