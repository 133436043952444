import { DirectionType } from '../../../../lib/defs/types';
import { Vector2 } from '../../../../lib/math/Vector2';
import { Particle } from '../Particle';
import { IBehavior } from './IBehavior';

// types
//-----------------------------------------------------------------------------
export type GravityBehaviorOptions = {
    type: 'gravity';
    gravity: DirectionType;
};

/*
    gravity dynamics
*/
export class GravityBehavior implements IBehavior {
    // fields
    //-------------------------------------------------------------------------
    private _particle: Particle;
    private readonly _options: GravityBehaviorOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(particle: Particle, options: GravityBehaviorOptions) {
        this._particle = particle;
        this._options = options;
    }

    // impl
    //-------------------------------------------------------------------------
    public step(dt: number) {
        const a = new Vector2(this._options.gravity.x, this._options.gravity.y);

        // v1 = v0 + a * dt
        this._particle.velocity.add(a.multiplyScalar(dt));
    }
}
