import { SB } from '@play-co/replicant';

// state
//-----------------------------------------------------------------------------
export const teamState = {
    teams: SB.map(
        SB.object({
            group: SB.array(SB.string()), // hero ids
        }),
    ),
};
