import { Sprite } from 'pixi.js';

import app from '../../../../index.entry';
import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { uiAlignCenterX, uiAlignCenterY } from '../../../../lib/pixi/uiTools';
import { PetItemId, petItemPropsMap } from '../../../../replicant/defs/items';
import { ImageButton } from '../buttons/ImageButton';
import { ScrollBox } from '../containers/ScrollBox';
import { BasicText } from '../text/BasicText';
import { BasicPopup } from './BasicPopup';

const HEIGHT = 740;

// types
//-----------------------------------------------------------------------------
export type PetHelpPopupOptions = {
    onClose: BasicAsyncHandler;
    items: PetItemId[];
    title: string;
};

const manifest = {
    itemFrame: 'frame.item.png',
};

/*
    General pet help popup
*/
export class PetHelpPopup extends BasicPopup {
    // injected
    onItemPress: (id: PetItemId) => void;

    private _items: ImageButton[];

    public get items() {
        return this._items;
    }

    public override preload() {
        return [
            ...super.preload(),
            ...app.resource.loadAssets([
                ...Object.values(manifest),
                ...Object.keys(petItemPropsMap).map((key: PetItemId) => petItemPropsMap[key].icon),
            ]),
        ];
    }

    // @ts-ignore
    public override async spawning(options: PetHelpPopupOptions) {
        super.spawning({
            width: 600,
            height: HEIGHT,
            ...options,
        });

        // clean out old references or create new one
        this._items = [];

        const scroll = this._createItemScroll(options.items);

        const header = new BasicText({
            text: options.title,
            style: {
                fill: ['FFFFFF', 'FFE1B4'],
                fillGradientStops: [0.4],
                fillGradientType: 0,
                strokeThickness: 4,
                fontSize: 38,
                fontWeight: 'bold',
                lineJoin: 'round',
                dropShadow: true,
                dropShadowDistance: 3,
                dropShadowAngle: 1,
                dropShadowColor: '12163C', // dark blue shadow
            },
        });
        // spawn
        this.main.addContent({
            header: {
                content: header,
                styles: {
                    position: 'topCenter',
                    marginTop: -58,
                },
            },
            scroll: {
                content: scroll,
                styles: {
                    position: 'topCenter',
                    marginTop: 10,
                },
            },
        });
    }

    public override async spawned(): Promise<void> {
        await super.spawned();
    }

    public override async despawned() {
        super.despawned();
    }

    private _createItemScroll(items: PetItemId[]) {
        const itemScroll = new ScrollBox({
            direction: 'down',
            width: this.base.width - 60,
            height: this.base.height - 150,
        });

        let y = 0;
        for (const id of items) {
            const itemProps = petItemPropsMap[id];

            const icon = Sprite.from(itemProps.icon);

            // TODO add to frame container
            const petItem = new ImageButton({
                image: manifest.itemFrame,
            });

            const itemLabel = new BasicText({
                text: itemProps.name,
                style: {
                    fill: '#FFE3C1',
                    fontSize: 28,
                    stroke: 0x000,
                    strokeThickness: 4,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                },
            });

            petItem.button.addChild(icon, itemLabel);
            uiAlignCenterY(petItem.button, icon);
            icon.position.set(30, 30);

            itemLabel.position.set(180, 65);

            petItem.onPress = async () => {
                this.onItemPress?.(id);
            };

            petItem.y = y;
            y += petItem.height + 10;
            uiAlignCenterX(itemScroll, petItem);

            itemScroll.content.addChild(petItem);
            // this._items[id] = petItem;
            this._items.push(petItem);
        }

        return itemScroll;
    }
}
