import { waitAFrame } from '@play-co/astro';
import { fetchFingerprintPayload } from '@play-co/fingerprint';
import { analytics, GCInstant } from '@play-co/gcinstant';
import { configureExtensions } from '@play-co/gcinstant/replicantExtensions';
import {
    ClientReplicant,
    createClientReplicant,
    dev_indexAllUsers,
    parseReplicantPlatform,
    ReplicantFromConfig,
} from '@play-co/replicant';

// TODO export from root
import { addSentryContext } from '../../app/lib/sentry';
import { onError } from '../../app/lib/util/errors';
import { openOA } from '../../lib/util/officialAccount';
import { INVITE_FEATURE } from '../../replicant/analytics/invite';
import { isFullTutorialComplete } from '../../replicant/components/tutorial';
import config from '../../replicant/config';
import { hexDecode } from '../../replicant/util/encoder';
import { computeAmplitudeAnalytics } from './HardwareAnalytics';

class InstantGameClass {
    public platform = GCInstant;
    public replicant!: ReplicantClient;

    private resolveReplicantClient: (replicant: ReplicantClient) => void;
    public replicantClientPromise: Promise<ReplicantClient> = new Promise((resolve) => {
        this.resolveReplicantClient = resolve;
    });

    public async init(): Promise<void> {
        try {
            await this.platform.initializeAsync({
                appID: process.env.APP_NAME,
                oauthAuthorizationCodeUrl: `${process.env.REPLICANT_ENDPOINT}/latest/oauth/getAccessToken`,
                shortName: process.env.SHORT_NAME,
                isDevelopment: process.env.NODE_ENV === 'development',
                version: VERSION,
                disableWebPush: true,
                disableAutomaticTosPopup: true, // TODO enable once decision is made
                webSkipLineUserInfoFetch: true, // Remove if profile is needed (name+pic). Skipping it results in 1 less api call
            });
        } catch (error) {
            // user cancelled login, error reported in amplitude from gcinstant under PlatformInitFailed
            if (error.code === 'UNAUTHORIZED') {
                // User cancelled login, stop everything.
                openOA();
                return;
            }
            // UNEXPECTED ERROR
            throw error;
        }

        this.platform.setFallbackPayloadGetter(fetchFingerprintPayload);

        this.overrideOfflinePlayerID();

        this.replicant = await createReplicantClient();
        this.resolveReplicantClient(this.replicant);

        addSentryContext();

        // Set up ad revenue tracking on FB, see https://docs.dev.gc-internal.net/gcinstant/ads/:
        if (this.platform.platformID === 'fb') {
            this.platform.setECPM(this.replicant.extras.getECPM());
        }

        // Sentry breadcrumbs for replicant actions
        // this.replicant.setActionPreInvokeCallback((action, args) => addBreadcrumb('replicant', `Invoking action "${action}"`, args));
        // this.replicant.setActionCancelledCallback((action, args) => addBreadcrumb('replicant', `Action "${action}" was cancelled`, args, Severity.Error));
        // this.replicant.setActionFailedCallback((action, args) => addBreadcrumb('replicant', `Action "${action}" failed`, args, Severity.Error));

        // initialise analytics
        const deviceProps = computeAmplitudeAnalytics();

        const userProps = {
            ...deviceProps,
            realtimeTutorialFinished: isFullTutorialComplete(this.replicant.state),
        };
        // Set custom user properties
        analytics.setUserProperties(userProps);

        this.replicant.setOnError(onError);

        this.assignManualTests();

        // debug stuff
        if (process.env.IS_DEVELOPMENT) {
            // offline testing
            if (process.env.REPLICANT_OFFLINE) {
                // do this for index search to work right
                await dev_indexAllUsers(this.replicant);

                // offline emulate scheduled actions. this simply fires all scheduled actions, regardless of their delays.
                //setInterval(() => dev_executeAllScheduledActions(this.replicant), timeFromComponents({ seconds: 5 }));

                // fix socialFriends being empty in mock env
                if (process.env.PLATFORM === 'mock') {
                    GCInstant.socialFriends = GCInstant.friends;
                }
            }

            // print stuff
            console.log('Replicant.UserID', this.replicant.userId);
            console.log('Replicant.State', this.replicant.state);
        }
    }

    /** Hides the loading screen */
    public async start(): Promise<void> {
        await waitAFrame();

        await this.platform.startGameAsync();
    }

    public async sendEntryFinal(): Promise<void> {
        // TODO set friend analytics if we build our own friend list ingame
        // this.platform.setFriendAnalytics({
        //     activeFriendCounts: friendsEntryData.activeFriendCounts,
        //     clusterCounts: friendsEntryData.clusterCounts,
        // });

        this.overrideEncodedPlayerIdAndZeroEntrySourcePlayerId();

        await this.platform.waitForEntryAnalytics();

        const entryData = GCInstant.entryData as { [key: string]: unknown };
        const entryFinalEventProps = {
            $creativeAssetID: entryData.$creativeAssetID,
            $creativeTextID: entryData.$creativeTextID,
            $creativeCTA: entryData.$creativeCTA,
            isAppSwitch: entryData.isAppSwitch,
        };

        // TODO need to fill in game specific props here
        void this.platform.sendEntryFinalAnalytics(entryFinalEventProps, {}, {});
    }

    public async refreshUserState(refreshSignature: boolean): Promise<void> {
        await this.replicant.refresh(refreshSignature ? GCInstant.playerSignature : null);
    }

    private overrideOfflinePlayerID() {
        if (!process.env.REPLICANT_OFFLINE) {
            return;
        }

        const overriddenID = this.getOverriddenPlayerID();

        if (overriddenID) {
            this.platform.playerID = overriddenID;
            this.platform.playerName = `Player ${overriddenID}`;
        }
    }

    private overrideEncodedPlayerIdAndZeroEntrySourcePlayerId() {
        const entryData = GCInstant.entryData as { [key: string]: unknown };

        if (entryData.feature === INVITE_FEATURE) {
            if ((entryData.playerID as string).startsWith('U')) {
                // Player ID starts with 'U' is an original LINE ID, no need to decode
                return;
            }

            const decodedPlayerId = hexDecode(entryData.playerID as string);
            GCInstant.entryData.playerID = decodedPlayerId;

            if (
                typeof entryData.zeroEntrySourcePlayerID === 'string' &&
                !entryData.zeroEntrySourcePlayerID.startsWith('U')
            ) {
                const decodedZeroEntrySourcePlayerId = hexDecode(GCInstant.entryData.zeroEntrySourcePlayerID);
                GCInstant.entryData.zeroEntrySourcePlayerID = decodedZeroEntrySourcePlayerId;
            }
        }
    }

    private getOverriddenPlayerID(): string | null {
        if (!process.env.REPLICANT_OFFLINE) {
            return null;
        }

        const param = window.location.search
            .replace(/\?/g, '')
            .split('&')
            .find((x) => x.startsWith('profile='));

        return param ? param.substr('profile='.length) : null;
    }

    private assignManualTests(): void {}
}

export const InstantGame = new InstantGameClass();

// https://docs.dev.gc-internal.net/gcinstant-replicant-extensions/index.html#usage
configureExtensions({ analytics, gcinstant: GCInstant, replicantClientPromise: InstantGame.replicantClientPromise });

export type ReplicantClient = ClientReplicant<ReplicantFromConfig<typeof config>>;

async function createReplicantClient(): Promise<ReplicantClient> {
    // force line
    const platform = parseReplicantPlatform('line');

    if (!platform) {
        throw Error(`Invalid Replicant platform: ${process.env.PLATFORM}`);
    }

    // line (web) and mock
    return (await createClientReplicant(config, GCInstant.playerID, {
        platform,
        endpoint: process.env.REPLICANT_ENDPOINT,
        signature: GCInstant.playerSignature,
        obtainSignature: () => GCInstant.refreshPlayerSignature(),
    })) as unknown as ReplicantClient;
}
