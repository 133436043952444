export const treatItemIds = ['healingPotion', 'mendingPoultice', 'superElixir'] as const;
export const forageItemIds = ['dewFlower', 'greenMoss', 'mushroom'] as const;

export const petItemIds = [...treatItemIds, ...forageItemIds] as const;

export type PetItemId = (typeof petItemIds)[number];
export type TreatItemId = (typeof petItemIds)[number];
export type ForageItemId = (typeof petItemIds)[number];

export type ItemProps = { name: string; icon: string; limit: number };

const defaultLimit = 9999;

export const petItemPropsMap: Record<PetItemId, ItemProps> = {
    dewFlower: {
        name: '[itemPetsDewFlower]',
        icon: `item.pets.dew.flower.png`,
        limit: defaultLimit,
    },
    greenMoss: {
        name: '[itemPetsGreenMoss]',
        icon: `item.pets.green.moss.png`,
        limit: defaultLimit,
    },
    healingPotion: {
        name: '[itemPetsHealingPotion]',
        icon: `item.pets.healing.potion.png`,
        limit: defaultLimit,
    },
    mendingPoultice: {
        name: '[itemPetsMendingPoultice]',
        icon: `item.pets.mending.poultice.png`,
        limit: defaultLimit,
    },
    mushroom: {
        name: '[itemPetsMushroom]',
        icon: 'item.pets.mushroom.png',
        limit: defaultLimit,
    },
    superElixir: {
        name: '[itemPetsSuperElixir]',
        icon: 'item.pets.super.elixir.png',
        limit: defaultLimit,
    },
};
