import app from '../../index.entry';
import { AbTestId } from '../../replicant/components/abtests';
import { abTests } from '../../replicant/ruleset';
import { windowReload } from '../lib/util/device';
import { CheatLayout, CheatProp, CheatSection } from '../world/cheat/CheatMenu';

// layout
//-----------------------------------------------------------------------------
export const testCheatLayout: CheatLayout = [
    // support actions
    [
        [
            {
                type: 'button',
                label: 'RELOAD',
                onPress: onReload,
            },
        ],
        [
            {
                type: 'button',
                label: 'AB DEFAULT & RELOAD',
                onPress: onDynamicDefault,
            },
        ],
    ],
    // ab tests
    abTestBuilder(),
];

// private: events
//-----------------------------------------------------------------------------
async function onReload() {
    // reload game
    windowReload();
}

async function onDynamicDefault() {
    // reload game
    await app.platform.resetAbTests();
    windowReload();
}

// ab test
async function onTestEnable(id: AbTestId, bucketId: string) {
    return app.platform.enableAbTest(id, bucketId);
}

async function onTestDisable(id: AbTestId) {
    return app.platform.disableAbTest(id);
}

// private: builders
//-----------------------------------------------------------------------------
function abTestBuilder(): CheatSection {
    return (Object.entries(abTests) as any[])
        .reverse()
        .map(
            ([id, test]) =>
                [
                    [
                        {
                            type: 'label',
                            label: id,
                        },
                    ],
                    [
                        {
                            type: 'button',
                            label: 'OFF',
                            selected: () => !app.platform.getAbTest(id),
                            onPress: () => onTestDisable(id as AbTestId),
                        },
                        ...test.buckets.map((bucket: { id: string }) => ({
                            type: 'button',
                            label: bucket.id,
                            selected: () => app.platform.getAbTest(id) === bucket.id,
                            onPress: () => onTestEnable(id as AbTestId, bucket.id),
                        })),
                    ],
                ] as CheatProp[][],
        )
        .flat();
}
