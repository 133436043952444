export type Ruleset = { abTests: typeof abTests };

/*
    Example

    'XXXX_TestId': {
        ...buckets(['Control', 'Enabled']),
        newUsersOnly: true, // optional
        assignManually: true, // optional
    }
*/

// ab tests
//-----------------------------------------------------------------------------
export const abTests = {
    // '0001_OaDelay60': {
    //     ...buckets(['Control', 'Enabled']),
    // },
} as const;

// private
//-----------------------------------------------------------------------------
function buckets<T extends string>(ids: T[]): { buckets: { id: T }[] } {
    return {
        buckets: ids.map((id) => ({ id })),
    };
}
