import { SB } from '@play-co/replicant';

import { getCreativeText } from '../chatbot/chatbotTexts';
import {
    chatbotMessageTemplates,
    FirstSessionAssetKey,
    generateChatbotPayload,
    ReplicantAssetKey,
} from '../chatbot/messageTemplates';
import { MutableState, ScheduledActionAPI, State, SyncActionAPI } from '../defs/replicant';
import { LanguageId } from '../defs/settings';
import { timeFromComponents } from '../util/timeTools';

// constants
//-----------------------------------------------------------------------------
const firstSession0 = 'first_session_0';
const firstSession1 = 'first_session_1';
const firstSession2 = 'first_session_2';

// actions
//-----------------------------------------------------------------------------
// scheduledActions
//-----------------------------------------------------------------------------
export const petScheduledActionsSchema = {
    firstSession: SB.object({ id: SB.string() }),
};

export const petScheduledActions = {
    firstSession: (state: MutableState, options: { id: ReplicantAssetKey }, api: ScheduledActionAPI) => {
        // any message sent, toggle flag to not re-schedule anything on next session
        if (!state.sentPetInitMessage) {
            state.sentPetInitMessage = true;
        }
        sendFirstSessionOA(state, api, options.id);
    },
};
// api
//-----------------------------------------------------------------------------

// events
//-----------------------------------------------------------------------------
export function onPetsInit(api: SyncActionAPI, state: MutableState) {
    // reset energy full event
    api.scheduledActions.unschedule(firstSession0);
    api.scheduledActions.unschedule(firstSession1);
    api.scheduledActions.unschedule(firstSession2);
}

export function onPetsExit(api: ScheduledActionAPI, state: State) {
    // if 1st message is successfully sent, dont schedule anymore messages
    if (state.sentPetInitMessage) return;

    const delayInMS = timeFromComponents({ minutes: 15 });

    // Check for tutorial completion right before the message send in case it got scheduled during the tutorial
    api.scheduledActions.schedule.firstSession({
        args: { id: 'firstSession0' },
        notificationId: firstSession0,
        delayInMS,
    });
    api.scheduledActions.schedule.firstSession({
        args: { id: 'firstSession1' },
        notificationId: firstSession1,
        delayInMS: timeFromComponents({ hours: 4 }),
    });
    api.scheduledActions.schedule.firstSession({
        args: { id: 'firstSession2' },
        notificationId: firstSession2,
        delayInMS: timeFromComponents({ hours: 8 }),
    });
}

export function sendFirstSessionOA(
    state: MutableState,
    api: ScheduledActionAPI | SyncActionAPI,
    id: FirstSessionAssetKey,
) {
    const lang = state.language as LanguageId;
    // chatbot text and asset key shares id
    const creativeText = getCreativeText(lang, id, api.math.random);
    // Default aspectRatio 1:1
    const aspectRatio = '3:2';

    api.chatbot.sendMessage(
        state.id,
        chatbotMessageTemplates.flexBubbleMessage({
            args: {
                imageKey: id,
                aspectRatio,
                text: creativeText.text,
                cta: creativeText.cta,
            },
            payload: {
                ...generateChatbotPayload('first_session'),
                $creativeAssetID: id,
            },
        }),
    );
}
